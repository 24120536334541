<template>
	<div class="ppsteam">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/home/index' }">概况总览</el-breadcrumb-item>
			<el-breadcrumb-item>直接销售详情</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="teamDetaial">
			<div class="topType">
				<span class="text">直接销售详情</span>
			</div>
			<div class="incomeList">
				<el-table :data="tableData">
					<el-table-column label="用户名" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.User.passport}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="paymentAt" label="购买时间" align="center"></el-table-column>
					<el-table-column prop="ppsOrder" label="数量" align="center"></el-table-column>
				</el-table>
				<div class="block">
				    <el-pagination  
					@current-change="handleCurrentChange" 
					layout="prev, pager, next, jumper" 
					:page-size="page.pagesize"
					:current-page="page.currentPage"
					:total="page.total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Distribution
	} from "@/api/index";
	export default {
		data() {
			return {
				tableData: [],
				page: {
					total: 0,
					pagesize: 10,
					currentPage: 1,
				},
			}
		},
		created() {
			this.ppsDetail()
		},
		methods: {
			async ppsDetail(page) {
				let {
					status,
					data
				} = await Distribution.ppsteam({page:page});
				if (status) {
					if (data.code == 0) {
						this.tableData = []
						this.tableData.push(...data.data.list);
						this.page.total = data.data.total;
						this.page.currentPage = data.data.currentPage;
					}
				}
			},
			//分页
			async handleCurrentChange(val) {
				this.page.currentPage = val;
				this.ppsDetail(this.page.currentPage)
			},
		}
	}
</script>

<style lang="less">
	.ppsteam {
		.el-breadcrumb {
			padding: 30px;

			.el-breadcrumb__inner a,
			.el-breadcrumb__inner.is-link {
				color: #999999;
				font-weight: 500;
			}

			.el-breadcrumb__item:last-child .el-breadcrumb__inner {
				color: #1493FA;
			}
		}

		.teamDetaial {
			background-color: #fff;
			margin: 20px;
			border-radius: 10px;
			.topType {
				background-color: #F8F8F8;
				padding: 9px 20px;
				border-top-right-radius: 10px;
				border-top-left-radius: 10px;

				.text {
					font-size: 18px;
					color: #333333;
					font-weight: 600;
					margin-left: 10px;
				}
			}

			.topType::before {
				content: "";
				display: inline-block;
				width: 3px;
				height: 16px;
				background: #1493FA;
				vertical-align: middle;
			}

			.incomeList {
				padding: 10px;
			}
			.el-pagination {
				text-align: right;
				padding: 10px 10px 0 0;
			}
		}
	}
</style>
